import DashboardRepository from '@/shared/http/repositories/socialProject/dashboard.js'
import ReportStudentRepository from '@/shared/http/repositories/socialProject/report-student.js'
import BarChart from '@/shared/components/BarChart'
import Pie from '@/shared/components/Pie'
import LineChart from '@/shared/components/Line'
import { toast, loading } from '@/shared/utils/UIHelper'
import StudentsByVenue from '@/shared/components/charts/studentsByVenue/studentsByVenue.vue'
import StudentsByModality from '@/shared/components/charts/studentsByModality/studentsByModality.vue'
import { globalActions as authActions } from '@/store/modules/auth/actionTypes'
import { mapActions } from 'vuex'
import ReportMixin from '@/views/report/dashboard/report-dashboard-mixin'
import { mapGetters } from 'vuex'
import { globalGetters as AuthGetters } from '@/store/modules/auth/getters'
import StudentMeetingCriteria from '@/shared/models/criteria/studentmeetingcriteria'
import StudentMeetingRepository from '@/shared/http/repositories/socialProject/student-meeting'
import { IsSuccessCode } from '@/shared/utils/API'
import { uniqBy } from 'lodash'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'

export default {
  name: 'Dashboard',

  components: {
    BarChart, Pie, LineChart, StudentsByVenue, StudentsByModality
  },

  mixins: [ReportMixin],

  data: () => ({
    dataModality: null,
    dataLocalTraining: null,
    dataPie: null,
    dataLine: null,
    studentsTotal: 0,
    optionsModality: null,
    optionsLocal: null,
    optionsPie: null,
    optionsLine: null,
    isChartLoading: false,
    showModalTodayMeeting: false,
    criteria: new StudentMeetingCriteria(),
    meetings: [],
    filteredMeeting: null,
    accountsIdWillSeeMeetings: [
      { id: 'E469388F-3E72-4BD1-99F3-03F547F808E9' }, // GUSTAVO COSTA
      { id: '98743F01-7034-43EC-9E95-2952D641EB8A' }, // RAUNITH
      { id: '506B23C6-A934-412E-82BC-9004EA10626E' }, // LEANDRO
      { id: '55E07DB0-5351-4E15-B04E-9986EC1C80AA' }, // ADMIN
      { id: '119118EF-B607-4549-AC37-5A6151D65D40' }, // MARIA SELMA DA SILVA CABRAL - RJ
      { id: '556B5430-AB2A-4D85-BB58-A9FEA3C63949' }, // PAULO ROGERIO - RJ
    ]
  }),

  computed: {
    ...mapGetters({
      loggedInUser: AuthGetters.LOGGED_IN_USER
    })
  },

  created() {
    this.fillStudentsTotal()
    this.fillStudentsByCompany()
    this.checkAccountWillSeeMeeting()
  },

  mounted() {
    this.fetchLoggedInUserInfo()
  },

  methods: {
    ...mapActions({
      fetchLoggedInUserInfo: authActions.FETCH_USER_INFO
    }),

    checkAccountWillSeeMeeting() {
      setTimeout(() => {
        const accountLoggedIn = this.loggedInUser.id.toUpperCase()
        const accountsWithPermissionSeeMeeting = this.accountsIdWillSeeMeetings.some(x => x.id == accountLoggedIn)

        let date = new Date();
        let day = String(date.getDate()).padStart(2, '0')
        let month = String(date.getMonth() + 1).padStart(2, '0')
        let year = date.getFullYear()
        const todayDate = year + '-' + month + '-' + day

        if (accountsWithPermissionSeeMeeting) {
          this.criteria.meetingDate = todayDate
          this.getAllMeeting()
          return
        }
      }, 1500)
    },

    fillStudentsTotal() {
      DashboardRepository.GetStudentsTotal()
        .then(res => {
          this.studentsTotal = res.data.data.studentTotal
        })
        .catch(() => {
          toast.error('Não foi possível carregar o Dashboard inicial', 'ERRO')
        })
    },

    fillStudentsByCompany() {
      ReportStudentRepository.GetTotalByCompany()
        .then(res => {
          let result = res.data.result

          var localNames = result.map(v => v.name)
          var localValues = result.map(v => v.count)


          this.dataPie = {
            labels: localNames,
            datasets: [
              {
                data: localValues,
                backgroundColor: ['#00bb7c', '#2f4858'],
                borderColor: ['#f9ee10', '#f9ee10']
              }]
          }
          this.optionsPie = {
            animation: {
              duration: 2500
            }
          }
        })
        .catch(e => {
          loading.pop()
          console.log(`Erro ao tentar obter os dados para o gráfico "Alunos Por região": \n ${e}`)
        })
    },

    fillDataLineChart() {
      this.dataLine = {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho'],
        datasets: [
          {
            label: 'Alunos',
            backgroundColor: '#92CDE1',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            data: [40, 25, 30, 50, 90, 10, 20, 0]
          }]
      }
      this.optionsLine = {
        animation: {
          duration: 2500
        }
      }
    },

    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },

    getAllMeeting() {
      loading.push()
      StudentMeetingRepository.GetAll(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.meetings = res.data.data

          if (this.meetings.length == 0) {
            loading.pop()
            return Promise.resolve()
          }

          const filteredByStatus = this.meetings.filter(x => x.meetingStatus == 0)
          const filteredByName = uniqBy(filteredByStatus, 'meetingName').map(x => ({
            meetingName: x.meetingName,
            meetingDate: dateFormattedFilter(x.meetingDate),
            className: x.className,
            teacherName: x.teacherName,
            meetingStatus: x.meetingStatus
          }))

          const isMeetingDone = filteredByName.some(x => x.meetingStatus == 0)

          if (isMeetingDone) {
            this.filteredMeeting = filteredByName
            this.showModalTodayMeeting = true
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          toast.error(err, 'Erro ao pegar reuniões')
        })
    },

    closeModalMeeting() {
      this.showModalTodayMeeting = false
      this.meetings = []
      this.filteredMeeting = null
    },

    goToMeetings() { window.location='/ferramentas/reuniao-aluno' }
  }
}
