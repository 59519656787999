import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  name: 'Pie',
  data: () => ({
  }),
  props: {
    data: null,
    options: null
  },
  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart(this.data, this.options)
  },
  watch: {
    data () {
      this.renderChart(this.data, this.options)
    }
  }
}
