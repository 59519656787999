import ReportStudent from '@/shared/http/repositories/socialProject/report-student'
import VueHighcharts from 'vue2-highcharts'
import Highcharts from 'highcharts'

export default {
  components: {
    VueHighcharts
  },
  data () {
    return {
      isChartLoading: false,
      options: {
        chart: {
          type: 'column'
        },
        exporting: {
          chartOptions: { // specific options for the exported image
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true
                }
              }
            }
          },
          fallbackToExportServer: false
        },
        title: {
          text: ''
        },
        subtitle: {
          text:
            ''
        },
        xAxis: {
          categories: [],
          title: {
            text: null
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Número de Alunos',
            align: 'middle'
          },
          labels: {
            overflow: 'justify'
          }
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            events: {
              click: this.LoadStudents
            },
            dataLabels: {
              enabled: true,
              format: '{point.y}'
            }
          },
          bar: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          backgroundColor:
            (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF',
          shadow: true
        },
        credits: {
          enabled: false
        },
        series: [],
        cursor: 'pointer',
        point: {
          events: {
            click: function () {
              alert('Category: ' + this.category + ', value: ' + this.y)
            }
          }
        }
      }
    }
  },
  mounted () {
    this.isChartLoading = true
    ReportStudent.GetTotalByLocalTraining()
      .then(response => {
        let categoriesArr = []
        let dataArr = []
        let result = response.data.result
        var totalStudents = 0

        result.forEach(item => {
          dataArr.push({'y': item.count, 'id': item.id})

          if (item.name === 'ADPM') item.name = 'ABDA ADPM'
          if (item.name === 'ARENA') item.name = 'ABDA ARENA'
          if (item.name === 'BTC') item.name = 'ABDA BTC'
          if (item.name === 'CENTRO') item.name = 'ABDA CENTRO'
          if (item.name === 'CAMPO DO ORIENTE') item.name = 'ABDA ATLETISMO TREINAMENTO'
          if (item.name === 'HIPICA') item.name = 'ABDA HIPICA'
          if (item.name === 'PARQUE AQUÁTICO JÚLIO DELAMARE') item.name = 'ABDA JÚLIO DELAMARE'
          if (item.name === 'PREVE') item.name = 'PREVE'

          categoriesArr.push(item.name)
          totalStudents += item.count
        })

        let chartData = [
          {
            name: 'Número de Alunos',
            data: dataArr,
            colorByPoint: true,
            colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1']
          }
        ]
        this.options.title.series = chartData
        this.options.xAxis.categories = categoriesArr

        setTimeout(() => {
          this.$refs.barChart.delegateMethod('showLoading', 'Loading...')
          this.$refs.barChart.removeSeries(1)
          this.$refs.barChart.addSeries(chartData[0])
          this.$refs.barChart.hideLoading()

          this.$refs.barChart.chart.update({
            xAxis: {
              categories: categoriesArr,
              text: null
            }
          })

          this.$emit('totalStudents', totalStudents)
          this.isChartLoading = false
        }, 2000)
      })
      .catch(e => {
        this.isChartLoading = false
        console.log(`Erro ao tentar obter os dados para o gráfico "Alunos Por Locais de Treinamento": \n ${e}`)
      })
  },
  methods: {
    LoadStudents (e) {
      ReportStudent.GetStudentsByLocalTraining(e.point.id)
        .then(response => {
          this.$emit('selectSchool', response.data.result)
          this.$emit('localTraining', e.point.id, e.point.category)
        })
        .catch(e => {
          console.log(`Erro ao tentar obter os dados para o gráfico "Alunos Por Local": \n ${e}`)
        })
    }
  }
}
