import { render, staticRenderFns } from "./studentsByVenue.vue?vue&type=template&id=0a6ac230&"
import script from "./studentsByVenue.js?vue&type=script&lang=js&"
export * from "./studentsByVenue.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports