import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  name: 'BarChart',
  data: () => ({
  }),
  props: {
    data: null,
    options: null
  },
  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart(this.data, this.options)
  },
  watch: {
    data () {
      this.renderChart(this.data, this.options)
    }
  }
}
