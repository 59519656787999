import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'dashboard'
const resourceStudent = 'student'
const modality = 'modality'
const school = 'school'
const localtraining = 'localtraining'
const gender = 'totalbygender'

const GetIndex = () => SocialProjectManager.get(resourceName)
const GetStudentsTotal = () => SocialProjectManager.get(`${resourceName}/${resourceStudent}/total`)
const GetStudentsByModality = () => SocialProjectManager.get(`${resourceName}/${resourceStudent}/${modality}`)
const GetStudentsBySchool = () => SocialProjectManager.get(`${resourceName}/${resourceStudent}/${school}`)
const GetStudentsByLocalTraining = () => SocialProjectManager.get(`${resourceName}/${resourceStudent}/${localtraining}`)
const GetStudentsByGender = () => SocialProjectManager.get(`${resourceName}/${resourceStudent}/${gender}`)
// const GetBaseUrl = () => SocialProjectManager.GetBaseUrl()

const DashboardRepository = {
  GetIndex,
  GetStudentsTotal,
  GetStudentsByModality,
  GetStudentsBySchool,
  GetStudentsByLocalTraining,
  GetStudentsByGender,
  // GetBaseUrl
}

export default DashboardRepository